<template>
    <v-dialog v-model="show" max-width="1024px" persistent content-class="rounded-xl">
        <v-card height="600px" class="hide-scroll" :style="{ backgroundColor: data.backgroundColor, color: data.backgroundColor == 'white' ? 'black':'white' }">
            <v-row class="d-flex" style="height: 20px; position: sticky;top: 0; right: 0;">
                <v-col cols="12" class="d-flex justify-end pr-6">
                    <v-icon large :color="data.backgroundColor == 'white' ? 'black' : 'white'" @click="onCloseDialogPopup()">
                        mdi-close
                    </v-icon>
                </v-col>
            </v-row>
            <v-row class="d-flex" style="padding: 0px 30px 0px 30px;">
                <v-col cols="12">
                    <div style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                        <h2 style="font-family: 'AirNZ Black Italic'; font-size: 30px; font-weight: normal; line-height: 3rem; padding: 0 0 1.5rem 0;">
                            <span v-html="data.headingText"></span>
                        </h2>
                        <div style="width: 80%; padding-bottom: 0.7rem;">
                            <p v-html="data.bodyText"></p>
                        </div>
                        <div v-if="data.totalCircles && data.totalCircles > 0" style="display: flex; " 
                            :style="{ 
                                justifyContent: (data.totalCircles && data.totalCircles > 1) ? 'space-between' : 'center', 
                                width: data.totalCircles && 
                                        (data.totalCircles == 1) ? '100%' :
                                        (data.totalCircles == 2) ? '45%' :
                                        (data.totalCircles == 3) ? '65%' :
                                        (data.totalCircles == 4) ? '88%' :
                                        '100%'
                            }">
                            <div v-if="data.totalCircles >= 1 && data.circle1">
                                <!-- <v-img max-width="auto" style="object-fit: contain !important;"
                                :src="$bucket + '/image/dashboard-tiles/Travel.png'"/>
                                <p style="padding-top: 1rem;">Travel Fund</p> -->
                                <span v-html="data.circle1" style="object-fit: contain !important; text-align: center;"></span>
                            </div>
                            <div v-if="data.totalCircles >= 2 && data.circle2">
                                <span v-html="data.circle2" style="object-fit: contain !important; text-align: center;"></span>
                            </div>
                            <div v-if="data.totalCircles >= 3 && data.circle3">
                                <span v-html="data.circle3" style="object-fit: contain !important; text-align: center;"></span>
                            </div>
                            <div v-if="data.totalCircles >= 4 && data.circle4">
                                <span v-html="data.circle4" style="object-fit: contain !important; text-align: center;"></span>
                            </div>
                            <div v-if="data.totalCircles >= 5 && data.circle5">
                                <span v-html="data.circle5" style="object-fit: contain !important; text-align: center;"></span>
                            </div>
                            <div v-if="data.totalCircles >= 6 && data.circle6">
                                <span v-html="data.circle6" style="object-fit: contain !important; text-align: center;"></span>
                            </div>
                        </div>
                        <div style="padding-top: 1rem;">
                            <p v-html="data.questionHeadingText" style="font-size: 24px;"></p>
                        </div>
                        <div v-if="popup.submitted == false" class="col-12 my-0 py-0">
                            <v-form v-model="popup.validForm" ref="popupValidForm" lazy-validation>
                                <v-textarea 
                                    v-model="popup.response" 
                                    label="Write here..." 
                                    solo 
                                    rows="2"
                                    :rules="[(v) => !!v || 'Please enter your answer', (v) => (v.split(' ').length <= data.answerMaximumWord) || ('Max '+data.answerMaximumWord+' words')]"
                                    @keydown="event => limitWords(event, data.answerMaximumWord)"
                                ></v-textarea>
                            </v-form>
                        </div>
                        <v-col v-else cols="12">
                            <v-row no-gutters class="correct-answer d-flex justify-center">
                                <v-alert dense type="success" height="42px" color="#440099">Submitted</v-alert>
                            </v-row>
                        </v-col>
                    </div>
                    <v-row class="d-flex justify-space-between align-center px-1">
                        <v-col class="d-flex" :cols="popup.submitted == false ? 6:12" :class="popup.submitted == false ? 'justify-start':'justify-center'">
                            <div :class="popup.submitted == false ? 'pl-3' : ''">
                                <p v-if="data.isHaveTermCondition && data.termConditionLink" style="font-style: italic; font-size: 14px;" :style="{ paddingTop: popup.submitted == false ? '12px':'' }">
                                    <a :href="getTermConditionLink()" target="_blank" :style="{color: data.backgroundColor == 'white' ? 'black':'white'}" >
                                        Terms and Conditions</a> apply
                                </p>
                            </div>
                        </v-col>
                        <v-col v-if="popup.submitted == false" class="d-flex justify-end">
                            <div>
                                <v-btn :color="data.buttonColor" style="font-size: 16px; height: 45px; width: 180px; text-transform: none !important;" @click="submit()">
                                    <span v-html="data.buttonText" style="height: 23px;" :style="{ color: data.buttonColor == 'white' ? 'black' : 'white' }"></span>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            
        </v-card>
    </v-dialog>
</template>

<style scoped>
.hide-scroll{
    overflow-y: scroll;
}

.hide-scroll::-webkit-scrollbar {
    background: transparent !important; /* make scrollbar transparent */
    width: 0px;
}

.custom-height {
    height: 700px !important;
}
</style>


<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from "axios";

export default {
    props: {
        data: {
            default: {
                backgroundColor: "#1C1044",
                textColor: "white",
                headingText: "",
                bodyText: "",
                circle1: "",
                circle2: "",
                circle3: "",
                circle4: "",
                circle5: "",
                circle6: "",
                totalCircles: 0,
                questionHeadingText: "",
                answerMaximumWord: 25,
                isHaveTermCondition: false,
                termConditionLink: "",
                buttonText: "Submit",
                buttonColor: "white"
            },
        },
        reportTitle: {
            type: String,
            default: "",
        },
        show: {
            type: Boolean,
            default: false,
        },
        isPreview: {
			type: Boolean,
			required: true,
			default: false
		}
    },
    data() {
        return {
            popup: {
				response: "",
				submitted: false,
				validForm: true,
			}
        }
    },
    watch: {
        async show(newValue) {
            if (newValue && this.isPreview == false) {
                try {
                    const response = await axios.post("/system/activitylog/person/value/list", {
                        value: `${this.reportTitle} Popup`
                    });

                    // Check response status and data
                    if (response.status === 200 && Array.isArray(response.data)) {
                        this.popup.submitted = response.data.length > 0;
                    } else {
                        console.warn("Unexpected API response:", response);
                        this.popup.submitted = false;
                    }
                } catch (error) {
                    console.error("API call failed:", error);
                    this.popup.submitted = false;
                }
            }
        }
    },
    methods: {
        onCloseDialogPopup() {
            this.$emit("closeDialogPopup");
        },
        getTermConditionLink() {
            if (this.data.termConditionLink && this.$root.isURL(this.data.termConditionLink)) {
                return this.data.termConditionLink;
            } else if (this.data.termConditionLink && this.data.termConditionLink.slug) {
                return `${window.location.origin}/#/page/${this.data.termConditionLink?.slug || ''}`;
            }
            return `${window.location.origin}/#/`;
        },
        limitWords(event, wordLimit) {
			const inputField = event.target;
			const currentValue = inputField.value;
			const words = currentValue.split(' ');

			// Allow backspace, delete, arrow keys, etc.
			if (event.key === 'Backspace' || event.key === 'Delete' || 
				event.key === 'ArrowLeft' || event.key === 'ArrowRight' || 
				event.key === 'ArrowUp' || event.key === 'ArrowDown' || 
				event.key === 'Tab') {
				return;
			}

			// Prevent input if word count is at or over the limit
			if (words.length >= (wordLimit+1)) {
				event.preventDefault();
			}
		},
        submit() {
			if (this.popup.response == "" || this.popup.response.trim() === '') {
				this.$store.commit("showNotification", 
					{ 	
                        title: "Error",
						content: "Response is required.", 
						color: "#00AFB9",
						buttonText: "OK"
					}
				);
			} else if (this.$refs.popupValidForm.validate()) {
                if (this.isPreview == false) {
                    this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: (this.reportTitle + ' Popup'), response: this.popup.response });
                }
                this.onCloseDialogPopup();
				this.popup.response = ""
				this.$store.commit("showNotification", 
					{ 	
						title: "Awesome", 
						content: "Thank you for your submission.", 
						color: "#00AFB9",
						buttonText: "OK"
					}
				);
			}
		},
    },
}
</script>